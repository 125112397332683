body {
    background-color: white;
    color: black;
}

header {
    display: none;
}

/* Move the content up */
main {
    margin-top: 0;
}

@media (prefers-color-scheme: dark) {
    body {
        /* background-color: #282c34; */
        background-color: black;
        color: white;
    }
}


body {
    overflow: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: 2rem;
    /* Set a base font size for the root element */
}

h1 {
    font-size: 2rem;
    /* Set the font size of the h1 element to 3 times the base font size */
    height: 2rem;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}